import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { Text, TextVariant } from '@naf/text';
import { Grid, GridCol, GridRow } from '@naf/grid';
import { useAuth0 } from '@auth0/auth0-react';
import { TextListItem } from '@naf/text-list';
import { useDispatch } from 'react-redux';
import * as S from './Styles';
import { cloudinaryImage } from '../../utils/imageUrl';
import { useAuth0Token } from '../../hooks/useAuth0Token';
import StA from '../../styles/articles/StyledArticle';
import BlockContent from '../../components/block-content/BlockContent';
import useSelector from '../../redux/typedHooks';
import { actions as membershipActions } from '../../redux/modules/myMembership';
import { LayoutWithMainContent } from '../../components/layout/Layout';
import { BenefitsType } from '../../../../types/benefitsType';
import { DEFAULT_IMG_URL } from '../../lib/constants';
import BreadCrumb from '../../components/breadcrumb/BreadCrumb';
import { useWindowLocation } from '../../hooks/useWindowLocation';
import FeedbackSurvey from '../../components/feedbacksurvey/FeedbackSurvey';
import { createNorwegianDateString } from '../../utils/createNorwegianDateString';
import SchemaArticle from '../../components/structured-data/SchemaArticle';
import { LoaderContent } from '../LoaderPage';
import { useDocumentUrl } from '../../hooks/useDocumentUrl';
import { useGTMDataLayer } from '../../hooks/useGTMDataLayer';
import { DynamicVideoPlayer } from '../../components/video-player/DynamicVideoPlayer';
import { fetchInternalLink } from '../../lib/fetchInternalLink';
import CampaignBannerMapper from './CampaignBannerMapper';
import CtaButton from './CtaButton';
import ResponsiveImage from '../../components/image/ResponsiveImage';
import { InternalSearchCardType } from '../../components/layout/InternalSearchMetaData';
import { MetaData } from '../../components/metaData/MetaData';
import AppAnnouncement from './AppAnnouncement';
import RelatedBenefits from './RelatedBenefits';

interface Props {
  benefitArticle: BenefitsType;
}

export const BenefitArticle = ({ benefitArticle }: Props) => {
  const dataLayer = useGTMDataLayer();
  const windowPath = useWindowLocation();
  const { isAuthenticated } = useAuth0();
  const { simpleToken } = useAuth0Token();
  const dispatch = useDispatch();
  const history = useHistory();
  const application = useSelector((state) => state.application);

  const {
    slug,
    title,
    ingress,
    body,
    id,
    video,
    image,
    campaign,
    relatedContent,
    meta,
    seoConfig,
    partner,
    category,
    discount,
    discountHeader,
    discountText,
    keyInformation,
    callToAction,
    metaDisplay,
    urlParameters,
    displayNafApp,
  } = benefitArticle || {};

  const handleCtaBlankExternalTracking = useMemo(
    () =>
      (cta: { url: string; buttonText: string; type: string; internalEndpoint?: string }) =>
      async (e: React.MouseEvent<HTMLAnchorElement> & { target: { href?: string } }) => {
        const urlObject = new URL(cta.url);
        dataLayer?.push({
          event: 'cta_click',
          cta_text: cta.buttonText,
          cta_location: history.location.pathname,
          cta_url: cta.url,
          cta_hostname: urlObject.hostname,
        });
        if (cta.type === 'InternalEndpoint') {
          e.preventDefault();
          const windowReference = window.open('', '_blank');
          try {
            const link = await fetchInternalLink(
              cta.internalEndpoint as string,
              application.apimContentHub,
              simpleToken,
            );
            if (windowReference) {
              // this is a workaround for safari mobile: https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari
              windowReference.location.href = link;
            } else {
              window.open(link, '_blank');
            }
          } catch (error) {
            // fallback to url without query token
            if (windowReference) {
              windowReference.location.href = cta.url;
            } else {
              window.open(cta.url, '_blank');
            }
          }
          return false;
        }
        return true;
      },
    [application.apimContentHub, simpleToken, dataLayer, history.location],
  );

  const url = useDocumentUrl(slug);

  const categoriesState = useSelector((state) => state.sitestructure);
  const {
    data: customerInformation,
    meta: { fetchState },
  } = useSelector((state) => state.myMembership.customerInformation);

  useEffect(() => {
    if (simpleToken && fetchState === 'initial') {
      dispatch(membershipActions.getCustomerInformation.request(simpleToken));
    }
  }, [fetchState, dispatch, simpleToken]);

  if (!!id || typeof id !== 'undefined') {
    const seoDescription = seoConfig?.introduction ? seoConfig.introduction : ingress;
    const seoTitle = seoConfig?.title ? seoConfig.title : title;

    const mappedCategory = category?.slug ? categoriesState?.mappedCategories[category.slug].data : null;

    const getFormattedDate = (date: string) => createNorwegianDateString(new Date(date));

    const articleDate = meta && meta.publishAt ? meta.publishAt : '';

    const schemaImage =
      (image && image.publicId && cloudinaryImage(image.publicId, application, 1200, 630)) || undefined;

    const onClickBecomeAMember = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
      dataLayer?.push({
        event: 'cta_click',
        cta_text: 'Bli medlem',
        cta_location: history.location.pathname,
        cta_url: '/bli-medlem',
      });
      history.push('/bli-medlem');
    };

    const hideDiscountCode = !!discount && (!isAuthenticated || !customerInformation?.membership);

    return (
      <LayoutWithMainContent
        title={title}
        description={ingress || seoDescription}
        url={windowPath}
        imgUrl={schemaImage || DEFAULT_IMG_URL}
        gtmArgs={{
          page_type: 'BenefitPage',
          pageCategory: `${mappedCategory?.prettyUrl}/${title}`,
          contentId: id,
        }}
        isHiddenFromSearch={seoConfig?.isHiddenFromSearch}
        seoTitle={seoTitle}
        seoDescription={seoDescription}
        internalSearchMetaData={{
          cardType: InternalSearchCardType.Benefit,
          imagePublicId: image?.publicId,
          partnerName: partner?.partnerName,
          partnerLogoPublicId: partner?.logo?.publicId,
        }}
      >
        <div>
          <SchemaArticle
            title={title}
            description={ingress}
            imgUrl={schemaImage}
            datePublished={getFormattedDate(articleDate)}
            url={url}
            id={slug}
          />
          <Grid>
            <GridRow>
              <GridCol s="12" m="12" l="12" xl="12">
                <BreadCrumb />
                <GridRow>
                  <GridCol s="12" m="12" l="8" xl="8">
                    {image && !video && (
                      <S.ImageWrapper>
                        <S.ImageFigure>
                          <ResponsiveImage imageId={image?.publicId} altText={image?.altText || image?.caption} />
                        </S.ImageFigure>
                        {partner?.logo && (
                          <S.PartnerlogoWrapper>
                            <img src={partner.logo.srcSet?.jpgLarge} alt={partner.logo?.source_altText || ''} />
                          </S.PartnerlogoWrapper>
                        )}
                      </S.ImageWrapper>
                    )}
                    {video && (
                      <S.ImageWrapper>
                        <DynamicVideoPlayer video={video} />
                      </S.ImageWrapper>
                    )}
                    {title && (
                      <S.StyledHeaderText tag="h1" variant={TextVariant.Header1}>
                        {title}
                      </S.StyledHeaderText>
                    )}
                  </GridCol>
                </GridRow>
                <GridRow>
                  <GridCol s="12" m="12" l="8" xl="8">
                    {ingress && (
                      <>
                        <StA.Ingress tag="p" variant={TextVariant.Ingress}>
                          {ingress}
                        </StA.Ingress>
                        <StA.HRLine />
                      </>
                    )}
                    {body ? (
                      <>
                        {keyInformation && (
                          <>
                            <Text tag="h2" variant={TextVariant.Header2}>
                              {keyInformation.title}
                            </Text>
                            {keyInformation.items.length > 0 && (
                              <S.StyledTextList maxWidth={512} variant="check">
                                {keyInformation.items.map((keyItem: string) => (
                                  <TextListItem key={keyItem}>{keyItem}</TextListItem>
                                ))}
                              </S.StyledTextList>
                            )}
                          </>
                        )}
                        <S.DiscountWrapper>
                          {discount && !hideDiscountCode && (
                            <Text tag="h3" variant={TextVariant.Header3}>
                              {discountHeader || 'Slik får du fordelen'}
                            </Text>
                          )}
                          {discountText && !hideDiscountCode && (
                            <S.DiscountInfoText tag="p" variant={TextVariant.ArticleText}>
                              {discountText}
                            </S.DiscountInfoText>
                          )}
                          {discount && !hideDiscountCode && (
                            // only show discount text if logged in and member
                            <S.DiscountText tag="p" variant={TextVariant.ArticleText}>
                              {discount.text}
                            </S.DiscountText>
                          )}
                          <CtaButton
                            hideButton={hideDiscountCode} // hide button if discount text exist and user is not logged in or not member
                            callToAction={callToAction}
                            urlParameters={urlParameters}
                            onClickBecomeAMember={onClickBecomeAMember}
                            handleCtaBlankExternalTracking={handleCtaBlankExternalTracking}
                          />
                        </S.DiscountWrapper>
                        {campaign && campaign.length > 0 && (
                          <S.MobileWrapper>
                            <CampaignBannerMapper
                              campaign={campaign}
                              handleCtaBlankExternalTracking={handleCtaBlankExternalTracking}
                            />
                          </S.MobileWrapper>
                        )}
                        <StA.Body>
                          <BlockContent value={body} />
                        </StA.Body>
                      </>
                    ) : (
                      <LoaderContent />
                    )}
                    <MetaData meta={meta} metaDisplay={metaDisplay} />
                  </GridCol>
                  {campaign && campaign.length > 0 && (
                    <GridCol s="12" m="12" l="4" xl="4">
                      <S.DesktopWrapper>
                        <CampaignBannerMapper
                          campaign={campaign}
                          handleCtaBlankExternalTracking={handleCtaBlankExternalTracking}
                        />
                      </S.DesktopWrapper>
                    </GridCol>
                  )}
                </GridRow>
              </GridCol>
              {displayNafApp?.nafApp && <AppAnnouncement />}
            </GridRow>
            {relatedContent && relatedContent.length > 0 && <RelatedBenefits relatedContent={relatedContent} />}
            {!metaDisplay?.hideForm && (
              <GridRow>
                <GridCol s="12" m="12" l="8" xl="8">
                  <FeedbackSurvey contentUrl={windowPath} />
                </GridCol>
              </GridRow>
            )}
          </Grid>
        </div>
      </LayoutWithMainContent>
    );
  }
  return null;
};

export default BenefitArticle;
